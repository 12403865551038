import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import TextArea from 'react-textarea-autosize'
import html2canvas from 'html2canvas'
import axios from 'axios'
import shortid from 'shortid'

window.html2canvas = html2canvas

const Container = styled.section`
	width: 100%;
	// height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const SquareBox = styled.div`
	width: calc(320px*4);
	height: calc(320px*4);
	display: flex;
	justify-content: center;
	align-items: center;
	// margin-bottom: calc(-320*2px);
	// background-color: #fff;
`

const ShadowContainer = styled.div`
	width: calc(312px*4);
	height: calc(312px*4);
	background-color: #fff;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	border-radius: 2px;
	box-shadow: 0px 1px 2px rgba(0,0,0,0.5);
`

const ItemText = styled.div`
	width: calc(260px*4);
	height: calc(56px*4);
	display: flex;
	justify-content: center;
	align-items: center;
`

const ItemImage = styled.img`
	width: calc(260px*4);
	height: calc(230px*4);
	// margin-left: 30px;
	// margin-top: 70px;
	background-color: #ccc;
	// background-image: url('image.jpg');
	background-image: url('${props => props.image}');
	// background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
	background-size: cover;
	background-position: center;
`

const SaveBtn = styled.button`
	width: calc(312*4px);
	text-align: center;
	padding: calc(12*4px) 0;
	background-color: #2ecc71;
	border: none;
	border-radius: 8px;
	color: #fff;
	font-size: calc(24*4px);
	box-shadow: 0px 1px 2px rgba(0,0,0,0.25);
	margin-top: calc(12*4px);
`

const fontSizeMap = {
	1: 27 *4,
	2: 22 *4
}

class App extends React.Component {

	constructor() {
		super()
		this.state = {
			fontSize: 20*4,
			image: null,
			imageUrl: null,
			resultImage: null,
			modalVisible: false
		}
	}

	onTextAreaHeightChange(height, instance) {
		// this.setState({fontSize: fontSizeMap[instance.rowCount]})
	}

	onClickImage() {
		document.getElementById('fileinput').click()
	}

	onChangeImage(e) {
		// this.onClickSave(e)
		// this.setState({image: e.target.files[0]})
		// const reader = new FileReader()
		// reader.onload = u => {this.setState({imageUrl: u.target.result})}
		// reader.readAsDataURL(e.target.files[0])
		const formData = new FormData()
		// formData.append('image', this.state.image)
		formData.append('image', e.target.files[0])

		console.log(formData)

		axios
			.post('/api/upload', formData, {
				onUploadProgress: e => {
					this.setState({progress: (100 * e.loaded / e.total).toFixed(2)})
				}
			})
			.then(res => {
				console.log(res)
				this.setState({imageUrl: '/latest?hash='+shortid.generate()})
			})
	}

	onClickSave(e) {

		html2canvas(
			document.getElementById('imageBox'),
			// {height: 1200, width: 1200}
		).then(canvas => {
			const imgurl = canvas.toDataURL("image/jpeg", 1.0)
			this.setState({resultImage: imgurl, modalVisible: true})
		})

	}

	onClickCloseModal() {
		this.setState({modalVisible: false})
	}

	render() {
		return (
			<React.Fragment>
				<ResultModal image={this.state.resultImage} visible={this.state.modalVisible} onClose={this.onClickCloseModal.bind(this)} />
				<Container>
					<SquareBox id='imageBox'>
						<ShadowContainer>
							<ItemText>
								<TextArea
									style={{color: '#34495e', width: '100%', resize: 'none', border: 'none', textAlign: 'center', fontFamily: 'Anzu', fontSize: this.state.fontSize + 'px'}}
									minRows={1}
									maxRows={1}
									placeholder='テキストを入力'
									onHeightChange={this.onTextAreaHeightChange.bind(this)}
								/>
							</ItemText>
							<ItemImage onClick={this.onClickImage.bind(this)} image={this.state.imageUrl} />
						</ShadowContainer>
					</SquareBox>
					<SaveBtn onClick={this.onClickSave.bind(this)}>Save</SaveBtn>
					<input type='file' id='fileinput' onChange={this.onChangeImage.bind(this)} style={{'display': 'none'}} />
					<p style={{marginTop: '48px', fontSize: '68px'}}>{this.state.progress}%</p>
				</Container>
			</React.Fragment>
		)
	}

}

const ModalBg = styled.div`
	position: fixed;
	background-color: rgba(0,0,0,0.6);
	width: 100%;
	height: 100%;
	z-index: 99;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const CloseBtn = styled.button`
	width: calc(312*4px);
	text-align: center;
	padding: calc(12*4px) 0;
	background-color: #7f8c8d;
	border: none;
	border-radius: 8px;
	color: #fff;
	font-size: calc(24*4px);
	box-shadow: 0px 1px 2px rgba(0,0,0,0.25);
	margin-top: calc(12*4px);
`

class ResultModal extends React.Component {

	render() {
		return (
			this.props.visible ? <ModalBg>
				<img src={this.props.image} style={{'width': (312*4)+'px'}} />
				<CloseBtn onClick={this.props.onClose}>Close</CloseBtn>
			</ModalBg> : null
		)
	}

}

ReactDOM.render(<App />, document.getElementById('app'))